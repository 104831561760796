.App {
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #000;
  padding: 20px;
  color: white;
}

.App-main {
  padding: 20px;
}

.App-info, .App-contact {
  margin: 20px 0;
}

.App-contact a {
  color: #007bff;
  text-decoration: none;
}

.App-contact a:hover {
  text-decoration: underline;
}

.App-footer {
  background-color: #000;
  color: white;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
}
